import { bidRequestAutomatedQuery } from '@providers/GeneralProvider/GeneralProvider.queries';
import { UserContext } from '@providers/UserProvider/UserProvider';
import GraphQLResponse from '@services/GraphQLResponse';
import { alertMessage } from '@utils/common';
import { getBidAcceptances } from 'api/BidAcceptanceApi';
import { getCompanyInvites } from 'api/CompanyUserInviteAcceptanceApi';
import withTranslation from 'next-translate/withTranslation';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import Pusher from 'pusher-js';
import { Component, createContext } from 'react';
import PusherService from 'services/PusherService';

export const GeneralContext = createContext({
  pusher: {},
  pusherChannel: {
    bind: (_eventName, _callback) => {},
  },
  companyInvites: [],
  bidAcceptances: [],
  getAndSetCompanyInvites: () => {},
  getAndSetBidAcceptances: () => {},
  setGeneralProperty: () => {}
});

// This provider should be used to store "general" or "modelish" app wide state requirements.
// UI/Layout related state should be stored in the LayoutProvider

class GeneralProvider extends Component {
  constructor (props) {
    super(props);

    this.state = {
      unauthorizedRequestIds: [],
      requestChangeCount: 0,
      companyInvites: [],
      bidAcceptances: [],
      cloningIds: [],
      bidSubmissionChangeCount: 0,
      hasStaleData: false,
      isAutomated: false
    };

    this.pusher = new Pusher(
      process.env.NEXT_PUBLIC_PUSHER_KEY,
      { cluster: process.env.NEXT_PUBLIC_PUSHER_CLUSTER }
    );

    this.pusherChannel = this.pusher.subscribe(`user-${this.props.user.id}`);
    this.trueUserChannel = this.pusher.subscribe(`user-${this.props.user?.true_user?.id}`);

    this.setGeneralProperty = this.setGeneralProperty.bind(this);
    this.incrementRequestChangeCount = this.incrementRequestChangeCount.bind(this);
    this.getAndSetCompanyInvites = this.getAndSetCompanyInvites.bind(this);
    this.getAndSetBidAcceptances = this.getAndSetBidAcceptances.bind(this);
    this.getApolloGQLClient = this.getApolloGQLClient.bind(this);
    this.areCloneJobsComplete = this.areCloneJobsComplete.bind(this);
  }

  componentDidMount () {
    if (this.props.user?.impersonating) {
      this.trueUserChannel.bind('change', e => {
        const service = new PusherService(e, this);

        service.handleEvents();
      });
    }

    this.pusherChannel.bind('change', e => {
      const service = new PusherService(e, this);

      service.handleEvents();
    });

    if (this.props.user.is_deeplink){
      this.setBidRequestAutomated();
    }

  }

  componentWillUnmount () {
    this.isSubscribed = false;
    this.pusherChannel.unsubscribe();
  }

  setGeneralProperty (property) {
    this.setState(property);
  }

  // General non-stream notifications list functions

  getAndSetCompanyInvites () {
    return getCompanyInvites(['pending', 'bounced'])
      .then(response => {
        if (!response.success) return;

        const invites = response.company_invites;

        this.setGeneralProperty({
          companyInvites: invites
        });
      });
  };

  getAndSetBidAcceptances () {
    return getBidAcceptances(['not_answered', 'bounced'])
      .then(response => {
        if (!response.success) return;

        const acceptances = response.bid_acceptances;

        this.setGeneralProperty({
          bidAcceptances: acceptances
        });

      });
  };

  getApolloGQLClient () {
    return this.props.gqlClient;
  }

  setBidRequestAutomated () {
    const args = { where : { id: [this.props.user.deeplink_contextable_id] } };

    bidRequestAutomatedQuery(args).then(res => {
      if (res.errors){
        alertMessage(res.errors[0]);
        return;
      }
      const gqlResponse = new GraphQLResponse(res);

      this.setState({ isAutomated: gqlResponse.normalizeConnection('bidRequests')[0].automated });
    });
  }

  areCloneJobsComplete () {
    const cloningKeys = Object.keys(this.state).map(k => k.includes('cloning_'));
    const areCloneJobsRunning = cloningKeys.some(k => !!this.state[k]);

    // If at least one clone job is running return false. If none are running return true.
    return !areCloneJobsRunning;
  }

  incrementRequestChangeCount () {
    this.setState({ requestChangeCount: this.state.requestChangeCount + 1 });
  }

  // TODO: Add useMemo here
  render () {
    return (
      <GeneralContext.Provider
        displayName="GeneralProvider"
        value={{
          ...this.state,
          pusher: this.pusher,
          pusherChannel: this.pusherChannel,
          setGeneralProperty: this.setGeneralProperty,
          incrementRequestChangeCount: this.incrementRequestChangeCount,
          getAndSetCompanyInvites: this.getAndSetCompanyInvites,
          getAndSetBidAcceptances: this.getAndSetBidAcceptances,
          getApolloGQLClient: this.getApolloGQLClient,
          areCloneJobsComplete: this.areCloneJobsComplete
        }}
      >
        {this.props.children}
      </GeneralContext.Provider>
    );
  }
}

GeneralProvider.propTypes = {
  children: PropTypes.node.isRequired,
  gqlClient: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,

};

const exportedComponent = props => <UserContext.Consumer>
  {user => <GeneralProvider {...props} user={user} />}
</UserContext.Consumer>;

export default withRouter(withTranslation(exportedComponent, 'common'));

/*

State Documentation

unauthorizedRequestIds: Use to filter against when removing request associations from a distant component
requestChangeCount: Used with Request & Request Tabs to refetch data after any updates have been made to a request

*/
